

































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import ffButton from '@/components/public/Button.vue'
import { RadioGroup, Radio, Checkbox, CheckboxGroup, Toast, Field, Cell, CellGroup, Uploader, Button, DropdownMenu, DropdownItem, DatetimePicker, Popup } from 'vant'
import { addGA, compare } from '../utils'
import { namespace } from 'vuex-class'
const UID_MAX_LENGTH = 10
const appStore = namespace('app')



@Component({
  components: {
    ffButton,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup
  }
})
export default class Feedback extends Vue {
  private formQuestion = new FormData() // 已经选择答案
  private formObj: object = {}
  private openQuestion: object = {
    content: '',
    phoneModel: '',
    // eventTime: '',
    // clothes: '',
    accountId: '',
    image1: '',
    image2: '',
    image3: '',
    videoLink: '',
    fileList_1: [],
    fileList_2: [],
    fileList_3: []
  }

  private resetCollections: Array<any> = []

  private formType: string = '' // 当前问题选项类型
  private activeIdx: number = null // 当前所在问题索引 值为 null 时直接跳过选择题部分
  private nextIndex: number = 0 // 当前选项下一题的索引
  private checkVal: number[] = [] // 多选
  private radioVal: number = -1 // 单选
  private answers: Array<Array<number> | number | null> = []
  private showPicker: boolean = false
  // private pickTime: string = '';
  // private selectTime: object = {
  //   minDate: new Date(2020, 0, 1),
  //   maxDate: new Date(),
  //   currentDate: new Date()
  // };
  @appStore.State(state => state.transify) private transify: any
  @appStore.State(state => state.questions) private questions: any
  @appStore.State(state => state.feedbackOpen) private feedbackOpen: boolean
  @appStore.State(state => state.imageUpload) private imageUpload: boolean
  @appStore.Action('setHistory') private setHistory!: Function
  private history: object[] = []

  get nextStatus(): boolean {
    return this.activeNext()
  }
  get checkAllFill(): boolean {
    if (!this.openQuestion['content']) {
      return true
    }
    if (!this.openQuestion['phoneModel']) {
      return true
    }
    // if (!this.openQuestion['eventTime']) {
    //   return true;
    // }
    if (!this.openQuestion['accountId'] || !this.checkUidRules(this.openQuestion['accountId'])) {
      return true
    }
    if (
      !this.formatLink(this.openQuestion['image1']) ||
      !this.formatLink(this.openQuestion['image2']) ||
      !this.formatLink(this.openQuestion['image3']) ||
      !this.formatLink(this.openQuestion['videoLink'])
    ) {
      return true
    }
    if (
      !this.openQuestion['image1'] &&
      !this.openQuestion['image2'] &&
      !this.openQuestion['image3'] &&
      !this.openQuestion['videoLink'] &&
      !(this.openQuestion['fileList_1'][0] && this.openQuestion['fileList_1'][0]['file']) &&
      !(this.openQuestion['fileList_2'][0] && this.openQuestion['fileList_2'][0]['file']) &&
      !(this.openQuestion['fileList_3'][0] && this.openQuestion['fileList_3'][0]['file'])
    ) {
      return true
    }
    return false
  }
  setFormType(type: string, index: number, nextIdx: number): void {
    this.formType = type
    this.activeIdx = index
    this.nextIndex = nextIdx
  }
  activeNext(): boolean {
    return this.checkVal.length >= 1 || this.radioVal >= 0
  }
  handleNext(): void {
    if (!this.activeNext()) {
      return
    }
    switch (this.formType) {
      case 'radio':
        this.handleRadio()
        break
      case 'checkbox':
        this.handleCheck()
        break
    }
    this.activeIdx = this.nextIndex
  }
  @Watch('openQuestion.fileList_1', { immediate: true, deep: true })
  watchFile1(val: any, old: any) {
    if (val.length > 0) {
      this.resetCollections.push(1)
    } else {
      this.resetCollections.forEach((re, index) => {
        if (re === 1) {
          this.resetCollections.splice(index, 1)
        }
      })
    }
  }
  @Watch('openQuestion.fileList_2', { immediate: true, deep: true })
  watchFile2(val: any, old: any) {
    if (val.length > 0) {
      this.resetCollections.push(2)
    } else {
      this.resetCollections.forEach((re, index) => {
        if (re === 2) {
          this.resetCollections.splice(index, 1)
        }
      })
    }
  }
  @Watch('openQuestion.fileList_3', { immediate: true, deep: true })
  watchFile3(val: any, old: any) {
    if (val.length > 0) {
      this.resetCollections.push(3)
    } else {
      this.resetCollections.forEach((re, index) => {
        if (re === 3) {
          this.resetCollections.splice(index, 1)
        }
      })
    }
  }
  handleRadio(): void {
    // 处理单选题
    this.answers[this.activeIdx] = this.radioVal
    this.radioVal = -1 // clear radio
  }
  handleCheck(): void {
    // 处理多选题
    this.answers[this.activeIdx] = this.checkVal
    this.checkVal = [] // 清空多选题选项
  }
  handleSelectAnswers(): void {
    this.formObj = {
      desc: this.openQuestion['content'],
      phone_model: this.openQuestion['phoneModel'],
      // event_time: this.openQuestion['eventTime'],
      // clothes: this.openQuestion['clothes'],
      video: this.openQuestion['videoLink'],
      adv_account_id: this.openQuestion['accountId'],
      feedback_answers: this.answers,
      images: []
    }
    if (this.openQuestion['image1']) {
      this.formObj['images'].push(this.openQuestion['image1'])
    }
    if (this.openQuestion['image2']) {
      this.formObj['images'].push(this.openQuestion['image2'])
    }
    if (this.openQuestion['image3']) {
      this.formObj['images'].push(this.openQuestion['image3'])
    }
    this.formQuestion.append('json', JSON.stringify(this.formObj))
  }
  // onConfirmPickTime(time: Date) {
  //   this.openQuestion['eventTime'] = time.getTime() / 1000;
  //   this.pickTime = time.toLocaleString();
  //   this.showPicker = false;
  // }
  // 压缩图片
  compressImage(file: any): object {
    return new Promise((resolve, reject) => {
      // 图片小于1M不压缩
      // if (file.size < Math.pow(1024, 2)) {
      //     return success(file);
      // }
      /* eslint-disable */
      const name = file.name //文件名
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e: any) => {
        const src = e.target.result
        const img: any = new Image()
        img.src = src
        img.onload = (e: any) => {
          const w = img.width
          const h = img.height
          const quality = 0.92 // 默认图片质量为0.92
          // 生成canvas
          const canvas: any = document.createElement('canvas')
          const ctx: any = canvas.getContext('2d')
          // 创建属性节点
          const anw: any = document.createAttribute('width')
          anw.nodeValue = w
          const anh: any = document.createAttribute('height')
          anh.nodeValue = h
          canvas.setAttributeNode(anw)
          canvas.setAttributeNode(anh)

          // 铺底色 PNG转JPEG时透明区域会变黑色
          ctx.fillStyle = '#fff'
          ctx.fillRect(0, 0, w, h)

          ctx.drawImage(img, 0, 0, w, h)
          // quality值越小，所绘制出的图像越模糊
          const base64 = canvas.toDataURL('image/jpeg', quality) // 图片格式jpeg或webp可以选0-1质量区间

          // 返回base64转blob的值
          // 去掉url的头，并转换为byte
          const bytes = window.atob(base64.split(',')[1])
          // 处理异常,将ascii码小于0的转换为大于0
          const ab = new ArrayBuffer(bytes.length)
          const ia = new Uint8Array(ab)
          for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
          }
          file = new Blob([ab], { type: 'image/jpeg' })
          file.name = name

          resolve(file)
        }
        img.onerror = (e: any) => {
          reject(e)
        }
      }
      reader.onerror = (e: any) => {
        reject(e)
      }
    })
  }
  async handleFinish() {
    if (!this.openQuestion['content']) {
      return Toast(this.transify['ADV_FEEDBACK_DESCRIPTION_NEED'])
    }
    if (!this.openQuestion['phoneModel']) {
      return Toast(this.transify['ADV_FEEDBACK_PHONE_NEED'])
    }
    // if (!this.openQuestion['eventTime']) {
    //   return Toast(this.transify['ADV_FEEDBACK_BUGHINT']);
    // }
    if (!this.openQuestion['accountId'] || !this.checkUidRules(this.openQuestion['accountId'])) {
      return Toast(this.transify['ADV_FEEDBACK_UID_HINT'])
    }
    if (
      !this.formatLink(this.openQuestion['image1']) ||
      !this.formatLink(this.openQuestion['image2']) ||
      !this.formatLink(this.openQuestion['image3']) ||
      !this.formatLink(this.openQuestion['videoLink'])
    ) {
      return Toast(this.transify['ADV_FEEDBACK_LINK_FORMAT'])
    }
    if (
      !this.openQuestion['image1'] &&
      !this.openQuestion['image2'] &&
      !this.openQuestion['image3'] &&
      !this.openQuestion['videoLink'] &&
      !(this.openQuestion['fileList_1'][0] && this.openQuestion['fileList_1'][0]['file']) &&
      !(this.openQuestion['fileList_2'][0] && this.openQuestion['fileList_2'][0]['file']) &&
      !(this.openQuestion['fileList_3'][0] && this.openQuestion['fileList_3'][0]['file'])
    ) {
      return Toast(this.transify['ADV_FEEDBACK_IMAGE_VIDEO_NEED'])
    }

    const vm = this
    if (this.openQuestion['fileList_1'][0] && this.openQuestion['fileList_1'][0]['file']) {
      const file1: any = await this.compressImage(this.openQuestion['fileList_1'][0]['file'])

      vm.formQuestion.append('imageFile1', file1, file1.name)
    }
    if (this.openQuestion['fileList_2'][0] && this.openQuestion['fileList_2'][0]['file']) {
      const file2: any = await this.compressImage(this.openQuestion['fileList_2'][0]['file'])

      vm.formQuestion.append('imageFile2', file2, file2.name)
    }
    if (this.openQuestion['fileList_3'][0] && this.openQuestion['fileList_3'][0]['file']) {
      const file3: any = await this.compressImage(this.openQuestion['fileList_3'][0]['file'])

      vm.formQuestion.append('imageFile3', file3, file3.name)
    }
    this.handleSelectAnswers()
    this['$http'].post('/feedback', this.formQuestion).then((res: any) => {
      addGA('user send feed back', 'success')
      Toast['success'](this.transify['ADV_FEEDBACK_SUCCESS'])
      this['$http'].get('/history').then((res: any) => {
        this.history = res.data.feedback_history.sort(compare('submit_time', 'des'))
        this.setHistory(this.history)
        setTimeout(() => {
          this['$router'].push({ path: '/history/1' }).catch(() => {
            return
          })
        }, 1000)
      })
    })
  }
  checkUidRules(uid: string) {
    // return true
    return uid.length == UID_MAX_LENGTH
  }
  formatLink(link: string) {
    if (link === '') return true
    const linkReg = /(http|https):\/\/([\w.]+\/?)\S*/
    return linkReg.test(link)
  }
  mounted(): void {
    this.answers = new Array(this.questions.length).fill(null)
  }
  created(): void {
    if (!this.feedbackOpen) {
      this['$router'].push('/').catch(() => {
        return
      })
    }
  }
 
}
